import React, { Fragment, useState } from "react";
// import Footer from './Footer'
import Header from "../header/Header";

import dynamic from "next/dynamic";
const Footer = dynamic(() => import("./Footer"), {
  ssr: true,
  loading: () => <p className="text-center text-white">Loading...</p>,
});
import starWarsData from "../../data/starWarsData.json";

const Layout = ({ children }) => {
  const handleFooterClick = (link) => {
    window.location.href = link;
  };
  // useEffect(() => {
  //   document.documentElement.style.setProperty('--background', 'url("/particles-bg.webp")');
  // }, []);

  const mobile_logo = starWarsData?.logo.logo_content.mobile_logo_video;
  const desktop_logo = starWarsData.logo.logo_content.desktop_logo_video;

  const getsite= () => {
    if (typeof window !== 'undefined') {
      const currentDomain = window.location.hostname;
      return currentDomain
    }
  
    return '';
  };

  //const shouldHideFooter = typeof window !== 'undefined' && window.location.pathname.includes('/wix-web-development');
  const shouldHideFooter2 = typeof window !== 'undefined' && window.location.pathname.includes('/e-commerce-development');
  const [shouldHideFooter, setShouldHideFooter] = useState(false);
  return (
    <Fragment>
      <div className={`${!shouldHideFooter ? "header-layout min-h-[5rem] bg-white bg-[#753C62]" : ""}`}>
      <Header mobileLogo={mobile_logo} desktopLogo={desktop_logo} shouldHideFooter={shouldHideFooter} setShouldHideFooter={setShouldHideFooter} />
    </div>
      {children}
      {!shouldHideFooter && <Footer />}
      <div className=" py-2 sm:flex sm:justify-between sm:max-w-[50rem] sm:mx-auto">
        <p className="text-white text-center">
          © 2024 Techcelerant - All Rights Reserved
        </p>

        <div className="cursor-pointer flex items-center justify-center sm:space-x-2 text-white text-[14px]">
          <p onClick={() => handleFooterClick("/terms-and-conditions")}>
            Terms & Conditions &nbsp; |{" "}
          </p>
          <span onClick={() => handleFooterClick("/privacy-policy")}>
            <p>&nbsp; Privacy Policy</p>
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;

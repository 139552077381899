import React, { useState, useEffect } from "react";
import { useRouter } from 'next/router';
import dynamic from "next/dynamic";

const DesktopHeader = dynamic(() => import("./DesktopHeader"), { ssr: true });
const MobileMenu = dynamic(() => import("./MobileMenu"), { ssr: true });
const MobileMenu2 = dynamic(() => import("./MobileMenu2"), { ssr: true });

const Header = ({ mobileLogo, desktopLogo, shouldHideFooter, setShouldHideFooter }) => {
  
  const [isMobile, setIsMobile] = useState(false);
  const router = useRouter();

  const handleResize = () => {
    setIsMobile(window.innerWidth < 900);
  };

  const handleRouteChange = (url) => {
    setShouldHideFooter(
      url.includes('/wix-web-development') ||
      url.includes('/e-commerce-development') ||
      url.includes('/professional-web-development')||
      url.includes('/custom-web-development')
    );
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleResize();
      handleRouteChange(window.location.pathname);

      window.addEventListener("resize", handleResize);
      router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        window.removeEventListener("resize", handleResize);
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router.events]);

  return (
    <>
      {isMobile ? (
        !shouldHideFooter ? (
          <>
            <MobileMenu mobileLogo={mobileLogo} />
            
          </>
        ) : (<>
          <MobileMenu2 mobileLogo={mobileLogo} />
          
        </>)
      ) : (
        !shouldHideFooter && <DesktopHeader desktopLogo={desktopLogo} />
      )}
    </>
  );
};

export default Header;

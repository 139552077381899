import { useEffect } from 'react';

const ZendeskChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=ab628bc3-a806-4cd8-a6c6-5bfc1e39c69d';
    script.id = 'ze-snippet';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default ZendeskChat;
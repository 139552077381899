import React from "react";
import Script from "next/script";
// import Head from 'next/head';
// import { Partytown } from '@builder.io/partytown/react';

export const GoogleScripts = () => {
  return (
    <>
      {/* <Head>
        <Partytown debug={true} forward={['dataLayer.push']} />
      </Head> */}

      <Script
        id="google-script-for-gtm"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="lazyOnload" id="gtm-script">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    });
                `}
      </Script>

      <Script id="gtm-script-2" strategy="lazyOnload">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
           new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
           j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
           'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
           })(window,document,'script','dataLayer','GTM-PD4SZ39F'); `}
      </Script>
    </>
  );
};

export const PWAScripts = () => {
  return (
    <>
      {/* <Head>
        <Partytown debug={true} forward={['dataLayer.push']} />
      </Head> */}

      <Script
        strategy="lazyOnload"
        src="https://static.elfsight.com/platform/platform.js"
        data-use-service-core
        defer
      ></Script>
    </>
  );
};

import { useEffect } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import * as fbq from "../lib/fpixel";
import axios from "axios";

import { v4 as uuidv4 } from 'uuid';
import useClientIP from "@/hooks/useClientIP";

const FacebookPixels = ({ eventId }) => {
  const router = useRouter();
  const ipAddress = useClientIP();
  const ipaddress = ipAddress; 
  function getClickId() {
    // Check for _fbc cookie
    const fbcCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbc='));
  
    if (fbcCookie) {
      console.log('Returning _fbc from cookie:', fbcCookie.split('=')[1]);
      return fbcCookie.split('=')[1];
    } else {
      console.warn('No _fbc cookie found');
    }
  
    // Generate _fbc from fbclid query parameter if _fbc cookie does not exist
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid');
    if (fbclid) {
      const subdomainIndex = 1; // Adjust this based on your domain structure
      const creationTime = Math.floor(new Date().getTime() / 1000); // Use current time as creation time
      const generatedFbc = `fb.${subdomainIndex}.${creationTime}.${fbclid}`;
      console.log('Generated _fbc from fbclid:', generatedFbc);
      return generatedFbc;
    } else {
      console.warn('No fbclid query parameter found');
    }
  
    // Generate a fallback _fbc value
    const subdomainIndex = 1;
    const creationTime = Math.floor(new Date().getTime() / 1000);
    const placeholderFbclid = '0000000000000000'; // Placeholder if no fbclid is found
    const fallbackFbc = `fb.${subdomainIndex}.${creationTime}.${placeholderFbclid}`;
    console.log('Generated fallback _fbc:', fallbackFbc);
    return fallbackFbc;
  }

  function getBrowserId() {
    // Logic to get Facebook Browser ID (fbp)
    const fbpCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('_fbp='));
    return fbpCookie ? fbpCookie.split('=')[1] : undefined;
  }

  async function sendPageViewEvent(eventId) {
    const userAgent = navigator.userAgent;
    const clickId = getClickId(); // Replace with your actual click ID logic
    const browserId = getBrowserId(); // Function to get Facebook Browser ID (fbp)
    const externalId = uuidv4(); // Replace with your actual external ID logic


    try {
      const response = await axios.post('/api/send-event-pageview', {
        eventId,
        clickId,
        userAgent,
        browserId,
        externalId,
        ipAddress
      });
  
      console.log('PageView event sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending PageView event:', error.response ? error.response.data : error.message);
    }
  }

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_CONTROLER !== "true") {
      return;
    }

    const handleRouteChange = async () => {
      //fbq.pageview();
      await sendPageViewEvent(eventId);
    };

    // Track initial pageview
    //fbq.pageview();
    sendPageViewEvent(eventId);

    // Add event listener to track route changes
    router.events.on("routeChangeComplete", handleRouteChange);

    // Remove event listener on component unmount
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_CONTROLER === "true" && (
        <>
          <Script
            id="facebook-browser"
            dangerouslySetInnerHTML={{
              __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID}');
        fbq('track', 'PageView', {}, {eventID: '${eventId}'}  );
        `,
            }}
          />

          {/* hotjar */}

          <Script
            id="hotjar-script"
            strategy="worker"
            dangerouslySetInnerHTML={{
              __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3710495,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
            }}
          />
        </>
      )}
    </>
  );
};

export default FacebookPixels;

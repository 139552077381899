// components/TawkToChat.js
import { useEffect } from "react";

const TawkToChat = () => {
  useEffect(() => {
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/64e84a0294cf5d49dc6c65ca/1h8lm26l9";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
    // Load the custom CSS
    const link = document.createElement("link");
    link.rel = "stylesheet";
    document.head.appendChild(link);

    s1.onload = function () {
      s1.onload = function () {
        const unwantedDiv = document.getElementById(
          "gno2us6tgnho1692946825943",
        );
        if (unwantedDiv) {
          unwantedDiv.remove();
        }
      };
    };


    
  }, []);

  return null;
};

export default TawkToChat;

import "@/styles/globals.css";
import React from "react";
//import "@/styles/phone-input.css";

import { GoogleScripts, PWAScripts } from "@/components/GoogleScripts";
import Layout from "@/components/layout/layout";
import FacebookPixels from "@/components/FacebookPixels";
import TawkToChat from "@/components/TawkToChat";

import { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import { Poppins } from "next/font/google";
import Router from "next/router";

import generateUniqueEventID from "@lib/generateUniqueEventID";
import { reportWebVitals } from "@/lib/webVitals";

import useMouseOver from "@/hooks/useMouseOver";
import Uettagscript from "@/components/Uettagscript";
import ZendeskChat from "@/components/ZendeskChat";

const progress = new ProgressBar({
  size: 4,
  color: "#4E3572",
  className: "z-50",
  delay: 100,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.Errors);

const poppins = Poppins({
  weight: ["500", "300", "400"],
  subsets: ["latin"],
  variable: "--font-popins",
});

var shouldRenderScripts;

export default function App({ Component, pageProps }) {
  const isMouseHover = useMouseOver();
  shouldRenderScripts = isMouseHover;

  const uniqueEventID = generateUniqueEventID();

  return (
    <>
   <TawkToChat />
    {/* <ZendeskChat/>*/}
      {shouldRenderScripts && (
        <>
          <FacebookPixels eventId={uniqueEventID} />
          <GoogleScripts />
          
          <Uettagscript/>
        </>
      )}
      <Layout>
        <main className={` ${poppins.className} `}>
          <Component {...pageProps} />
          <Toaster />
          <PWAScripts />
        </main>
      </Layout>
    </>
  );
}

export function reportWebVital(metric) {
  if (metric.label === "web-vital" && shouldRenderScripts) {
    reportWebVitals(metric);
  }
}
